import React from 'react';
import { View, Text, TextInput as TextInputRN } from 'react-native';
import CurrencyInput from 'react-native-currency-input';
import styles from './styles';

const TextInput = React.forwardRef(
  (
    {
      onChange,
      onBlur,
      name,
      label,
      value,
      inputStyles = {},
      error,
      isRequired = false,
      ...restProps
    },
    ref
  ) => {
    return (
      <View>
        {label && (
          <View style={styles.label}>
            <Text style={styles.labelText}>{label}</Text>
            {isRequired && <Text style={styles.labelRequired}>*</Text>}
          </View>
        )}

        <TextInputRN
          style={[styles.input, inputStyles]}
          onBlur={onBlur}
          onChangeText={onChange}
          value={value}
          ref={ref}
          name={name}
          {...restProps}
        />

        {error && <Text style={styles.errorMessage}>{error.message}</Text>}
      </View>
    );
  }
);

export const TextInputCustom = React.forwardRef(
  ({ onChange, name, value, ...restProps }, ref) => {
    return (
      <CurrencyInput
        onChangeValue={onChange}
        value={value}
        ref={ref}
        name={name}
        minValue={0}
        precision={0}
        renderTextInput={(textInputProps) => <TextInput {...textInputProps} />}
        renderText
        {...restProps}
      />
    );
  }
);

export default TextInput;
