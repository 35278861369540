import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SafeAreaView, FlatList, View } from 'react-native';
import LogoutIcon from '../../../assets/images/logout.svg';
import DownloadIcon from '../../../assets/images/download.svg';
import UserOverview from '../../../components/UserOverview';
import Plan from '../../../components/Plan';
import ActionButton from '../../../components/ActionButton';
import styles from './styles';
import { usePlans } from '../../../api/planApi';
import { getCurrentUser, doLogout } from '../../../store/slices/authSlice';
import { SCREEN_NAME } from '../../../constants/screens';

const PlanList = ({ navigation }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);

  const { data, refetch } = usePlans({
    params: { user_id: currentUser?.user_id },
    options: {
      enabled: false,
    },
  });

  const handleLogout = () => {
    // TODO: Should improve
    navigation.navigate(SCREEN_NAME.SIGN_IN);
    dispatch(doLogout());
  };

  return (
    <SafeAreaView style={styles.container}>
      {
        <FlatList
          contentContainerStyle={{ paddingHorizontal: 24, paddingTop: 24 }}
          data={data?.data ?? []}
          renderItem={({ item }) => (
            <Plan
              key={item.id}
              data={item}
              onPress={() =>
                navigation.navigate(SCREEN_NAME.WORKING, {
                  planId: item.plan_id,
                })
              }
            />
          )}
          keyExtractor={(item) => item.id}
          showsVerticalScrollIndicator={false}
          ListHeaderComponent={
            <>
              <UserOverview data={currentUser} progress={data?.progress} />

              <View style={styles.actions}>
                <ActionButton icon={<LogoutIcon />} onPress={handleLogout}>
                  Thoát tài khoản
                </ActionButton>
                <ActionButton icon={<DownloadIcon />} onPress={refetch}>
                  Tải ca làm việc
                </ActionButton>
              </View>
            </>
          }
        />
      }
    </SafeAreaView>
  );
};

export default React.memo(PlanList);
