import React from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import StoreIcon from '../../assets/images/store.svg';
import styles from './styles';

const Plan = ({ data, onPress }) => {
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <StoreIcon width={60} />

      <View style={styles.content}>
        <Text style={[styles.text, styles.name]}>{data.store_name}</Text>
        <Text style={[styles.text, styles.date]}>{data.plan_name}</Text>
        <Text style={styles.text}>{data.store_code}</Text>
        <Text style={styles.text}>{data.address}</Text>
        <Text style={styles.text}>Ca {data.working_time}</Text>
      </View>
    </TouchableOpacity>
  );
};

export default React.memo(Plan);
