import { StyleSheet } from 'react-native';
import colors from '../../constants/colors';

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: colors.black,
    borderRadius: 8,
    flex: 1,
    width: 130,
  },
  picker: {
    height: '100%',
  },
  pickerItem: {},
});

export default styles;
