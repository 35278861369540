import React from "react";
import { TouchableOpacity, Text } from "react-native";

const Button = ({ outline = false, disabled = false, children, onPress = () => {} }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disabled}
      className={`flex-row items-center justify-center px-2 py-2 rounded-[38px] min-w-[130px] ${
        outline ? "bg-white border-[#007FC3] border-[1px]" : "bg-[#007FC3]"
      }
      ${
        disabled ? "bg-[#cccccc]" : "bg-[#007FC3]"
      }
      `}
    >
      <Text
        className={`${
          outline ? "text-black" : "text-white"
        }  text-lg font-bold`}
      >
        {children}
      </Text>
    </TouchableOpacity>
  );
};

export default React.memo(Button);
