import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, SafeAreaView, ScrollView, StyleSheet } from 'react-native';
import colors from '../../constants/colors';
import ProfileInfo from '../../components/ProfileInfo';
import CustomButton from '../../components/CustomButton';
import { SCREEN_NAME } from '../../constants/screens';
import { getCurrentUser, doLogout } from '../../store/slices/authSlice';
import { useDeleteUser } from '../../api/authApi';
import { CustomAlert } from '../../components/CustomAlert';

const Profile = ({ navigation }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const deleteUserMutation = useDeleteUser();

  const handleShowConfirmDelete = () =>
    CustomAlert('Xác nhận xoá tài khoản', '', [
      {
        text: 'Đồng ý',
        onPress: handleDeleteAccount,
        style: 'cancel',
      },
      { text: 'Huỷ', onPress: () => {} },
    ]);

  const handleDeleteAccount = () => {
    deleteUserMutation.mutate(
      { user_id: currentUser?.user_id },
      {
        onSuccess() {
          handleLogout();
        },
      }
    );
  };

  const handleLogout = () => {
    navigation.reset({
      index: 0,
      routes: [{ name: SCREEN_NAME.SIGN_IN }],
    });
    dispatch(doLogout());
  };

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView
        contentContainerStyle={styles.contentContainer}
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.profileContainer}>
          <ProfileInfo user={currentUser} />

          <View style={styles.actions}>
            <CustomButton
              type='danger'
              onPress={() => navigation.navigate(SCREEN_NAME.CHANGE_PASSWORD)}
            >
              Đổi mật khẩu
            </CustomButton>

            <CustomButton type='danger' onPress={handleShowConfirmDelete}>
              Xoá tài khoản
            </CustomButton>

            <CustomButton onPress={handleLogout}>Đăng xuất</CustomButton>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  contentContainer: {
    padding: 24,
  },
  actions: {
    marginTop: 32,
  },
  profileContainer: {
    backgroundColor: colors.white,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 4,
    borderRadius: 8,
    paddingHorizontal: 8,
    paddingVertical: 16,
  },
});

export default React.memo(Profile);
