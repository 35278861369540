import React from 'react';
import { useSelector } from 'react-redux';
import { View } from 'react-native';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomTextInputPassword from '../../../components/CustomTextInput/CustomTextInputPassword';
import CustomButton from '../../../components/CustomButton';
import { CustomAlert } from '../../../components/CustomAlert';
import styles from './styles';
import { useChangePassword } from '../../../api/authApi';
import { getCurrentUser } from '../../../store/slices/authSlice';

const schema = yup.object().shape({
  oldpass: yup.string().required('Bắt buộc'),
  newpass: yup.string().required('Bắt buộc'),
  confirm_newpass: yup
    .string()
    .required('Bắt buộc')
    .oneOf([yup.ref('newpass'), null], 'Mật khẩu không khớp'),
});

const ChangePassword = ({ navigation }) => {
  const currentUser = useSelector(getCurrentUser);
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const changePasswordMutation = useChangePassword();

  const onSubmit = ({ confirm_newpass, ...values }) => {
    const data = {
      ...values,
      user_id: currentUser?.user_id,
    };

    changePasswordMutation.mutate(data, {
      onSuccess({ status, message }) {
        CustomAlert(message, '', [
          {
            text: 'Đồng ý',
            onPress: () => {
              if (status) {
                navigation.goBack();
              }
            },
          },
        ]);
      },
    });
  };

  return (
    <View style={styles.container}>
      <View style={styles.formGroupContainer}>
        <CustomTextInputPassword
          name='oldpass'
          control={control}
          label='Mật khẩu hiện tại'
          autoCapitalize='none'
          autoCorrect={false}
          secureTextEntry
        />
      </View>

      <View style={styles.formGroupContainer}>
        <CustomTextInputPassword
          name='newpass'
          control={control}
          label='Mật khẩu mới'
          autoCapitalize='none'
          autoCorrect={false}
          secureTextEntry
        />
      </View>

      <View style={styles.formGroupContainer}>
        <CustomTextInputPassword
          name='confirm_newpass'
          control={control}
          label='Nhập lại mật khẩu mới'
          autoCapitalize='none'
          autoCorrect={false}
          secureTextEntry
        />
      </View>

      <CustomButton onPress={handleSubmit(onSubmit)}>Lưu thay đổi</CustomButton>
    </View>
  );
};

export default React.memo(ChangePassword);
