import { StyleSheet } from 'react-native';
import { DEVICE_WIDTH } from '../../../constants/size';
import colors from '../../../constants/colors';

const styles = StyleSheet.create({
  container: {
    width: (DEVICE_WIDTH - 66) / 2,
    backgroundColor: colors.white,
    alignItems: 'center',
    borderRadius: 18,
    paddingVertical: 23,
    paddingHorizontal: 8,
    shadowColor: colors.black,
    shadowOpacity: 0.25,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 4,
    marginTop: 38,
    elevation: 4,
  },
  icon: {
    width: 75,
    height: 75,
    backgroundColor: colors.primary,
    borderRadius: 75 / 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  name: {
    fontSize: 18,
    color: colors.black,
    marginTop: 16,
  },
  checkedIcon: {
    position: 'absolute',
    right: -5,
    top: -20,
  },
});

export default styles;
