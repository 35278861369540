import { useQuery } from '@tanstack/react-query';
import axiosClient from './axiosClient';

export const cameraApi = {
  getAll(params) {
    return axiosClient.post('/home/list_camera', params);
  },
};

export const useGetCameraList = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['camera', 'list', params],
    queryFn: () => cameraApi.getAll(params),
    ...options,
  });
};
