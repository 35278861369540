import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosClient from './axiosClient';

export const locationApi = {
  checkIn(data) {
    return axiosClient.post('/home/check_in', data);
  },
  checkOut(data) {
    return axiosClient.post('/home/check_out', data);
  },
};

export const useCheckIn = ({ params } = {}) => {
  const queryClient = useQueryClient();

  return useMutation(locationApi.checkIn, {
    onSuccess() {
      queryClient.invalidateQueries(['plans', 'detail', params]);
    },
  });
};

export const useCheckOut = ({ params } = {}) => {
  const queryClient = useQueryClient();

  return useMutation(locationApi.checkOut, {
    onSuccess() {
      queryClient.invalidateQueries(['plans', 'list', params]);
    },
  });
};
