import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  SafeAreaView,
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  Platform,
} from 'react-native';
import AvatarIcon from '../../assets/images/avatar.svg';
import ArrowIcon from '../../assets/images/arrow.svg';
import Button from '../../components/Button';
import styles from './styles';
import { usePlan } from '../../api/planApi';
import { SCREEN_NAME } from '../../constants/screens';
import { formatTime } from '../../utils/timeUtils';
import { getCurrentUser } from '../../store/slices/authSlice';
import { sumTotalValueWithDuplicateKey } from '../../utils/arrayUtils';
import { formatCurrency } from '../../utils/formatUtils';
import { useGetCameraList } from '../../api/cameraApi';
import ButtonOpenCamera from '../camera/components/ButtonOpenCamera';
import BoxImage from '../../components/BoxImage';
import { DEVICE_WIDTH } from '../../constants/size';
import { useCreateImage, useDeleteImage } from '../../api/imageApi';
import { CustomAlert } from '../../components/CustomAlert';

const ConfirmInfoView = ({ navigation, route }) => {
  const { planId } = route.params;
  const currentUser = useSelector(getCurrentUser);
  const createImageMutation = useCreateImage();
  const deleteImageMutation = useDeleteImage();

  const { data, isLoading, isError, refetch } = usePlan({
    params: { plan_id: planId },
  });

  const { data: cameraCheckoutId } = useGetCameraList({
    options: {
      enabled: !!data?.plan_id,
      select(response) {
        return response?.check_out?.[0]?.id ?? null;
      },
    },
  });

  if (isLoading) {
    return null;
  }

  if (isError || !data?.plan_id) {
    return null; // Show error
  }

  const handleAddImage = async (id, image) => {
    console.log({ id, image });
    const localUri = image.uri;
    const filename = localUri.split('/').pop();
    const match = /\.(\w+)$/.exec(filename);
    const type = match ? `image/${match[1]}` : `image`;

    const formData = new FormData();
    formData.append('user_id', currentUser.user_id);
    formData.append('plan_id', planId);
    formData.append('manual', 0);
    formData.append('shelves_id', id);
    formData.append('store_code', data.store_code);
    formData.append('create_on', new Date().getTime());
    formData.append('month', data.date_start_year_month);
    formData.append('image', {
      uri: localUri,
      name: filename,
      type: type,
    });
    let flag_web = Platform.OS === 'web';
    // console.log('Platform.OS', Platform.OS);
    // flag_web = true; // Default for branch web
    if (flag_web) {
      let image_blob = await fetch(image.uri).then((res) => res.blob());
      formData.append('image', image_blob);
    } else {
      formData.append('image', {
        uri: localUri,
        name: filename,
        type: type,
      });
    }

    createImageMutation.mutate(formData, {
      onSuccess() {
        refetch();
      },
    });
  };

  const handleDeleteImage = (id) => {
    deleteImageMutation.mutate(
      { plan_id: planId, id },
      {
        onSuccess() {
          refetch();
        },
      }
    );
  };

  const handleClickConfirm = () => {
    CustomAlert('Bạn có chắc chắn muốn submit?', '', [
      {
        text: 'Đồng ý',
        onPress: () => {
          navigation.navigate(SCREEN_NAME.MAP, {
            planId,
            isCheckout: true,
          });
        },
      },
      {
        text: 'Huỷ',
        onPress: () => {},
        style: 'cancel',
      },
    ]);
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#fff' }}>
      <ScrollView>
        <View style={{ padding: 24 }}>
          <View style={styles.cardInfo}>
            <AvatarIcon width={56} />
            <Text style={styles.username}>{currentUser?.fullname}</Text>
          </View>

          <View>
            <TouchableOpacity style={styles.share}>
              <ArrowIcon />
              <Text style={styles.shareText}>Chia sẻ</Text>
            </TouchableOpacity>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Ngày thực hiện:</Text>
            <Text style={styles.desc}>{formatTime(new Date())}</Text>
          </View>

          {/* <View style={styles.row}>
            <Text style={styles.label}>Ca làm việc:</Text>
            <Text style={styles.desc}>14h00 - 22h00</Text>
          </View> */}

          <View style={styles.row}>
            <Text style={styles.label}>Tỉnh:</Text>
            <Text style={styles.desc}>{data?.province}</Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Địa chỉ:</Text>
            <Text style={styles.desc}>{data?.address_full}</Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Tên CH:</Text>
            <Text style={styles.desc}>{data?.store_name}</Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Sample:</Text>
            <Text style={styles.desc}>{data?.count?.sample}</Text>
          </View>

          <View style={styles.list}>
            <Text style={styles.label}>Quà tặng:</Text>

            <View style={styles.listContent}>
              {_.map(
                sumTotalValueWithDuplicateKey(data?.count?.gift, 'id'),
                (item) => {
                  return (
                    <Text
                      key={item.gift_id}
                      style={{ fontSize: 20 }}
                    >{`\u2022 ${item.name}: ${formatCurrency(
                      item.value
                    )}`}</Text>
                  );
                }
              )}
            </View>
          </View>

          <View style={styles.list}>
            <Text style={styles.label}>Mua hàng:</Text>

            <View style={styles.listContent}>
              {_.map(
                sumTotalValueWithDuplicateKey(
                  data?.count?.product,
                  'sanpham_id'
                ),
                (item) => {
                  return (
                    <Text
                      key={item.sanpham_id}
                      style={{ fontSize: 20 }}
                    >{`\u2022 ${item.attr_name}: ${formatCurrency(
                      item.value
                    )}`}</Text>
                  );
                }
              )}
            </View>
          </View>

          <View
            style={[
              styles.list,
              { flexDirection: 'row', alignItems: 'center' },
            ]}
          >
            <Text style={[styles.label, { width: 'auto' }]}>Doanh số:</Text>
            <Text style={{ fontSize: 20, marginLeft: 8 }}>
              {formatCurrency(data?.price_total)}
            </Text>
          </View>

          <View style={[styles.list]}>
            <Text style={[styles.label, { width: 'auto' }]}>
              Chụp hình check out:
            </Text>
            <View
              style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 16 }}
            >
              {data?.store_image
                ?.filter((item) => +item.shelves_id === +cameraCheckoutId)
                ?.map((item, index) => {
                  const width = (DEVICE_WIDTH - 118) / 3;

                  return (
                    <BoxImage
                      styleContainer={{
                        marginRight: index % 3 === 2 ? 0 : 20,
                        marginBottom: index % 3 === 0 ? 0 : 20,
                      }}
                      styleImage={{
                        width,
                        height: width,
                      }}
                      link={item.link}
                      key={item.id}
                      onDelete={() => handleDeleteImage(item.id)}
                    />
                  );
                })}

              {/* Check maximum images */}
              <ButtonOpenCamera
                style={{
                  width: (DEVICE_WIDTH - 118) / 3,
                  height: (DEVICE_WIDTH - 118) / 3,
                }}
                onPress={() =>
                  navigation.navigate(SCREEN_NAME.CAMERA, {
                    callback: (image) =>
                      handleAddImage(cameraCheckoutId, image),
                  })
                }
              />
            </View>
          </View>

          <View style={styles.btnWrapper}>
            <View style={{ marginHorizontal: 5 }}>
              <Button onPress={handleClickConfirm}>Confirm</Button>
            </View>
            <View style={{ marginHorizontal: 5 }}>
              <Button onPress={() => navigation.goBack()}>Cancel</Button>
            </View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default React.memo(ConfirmInfoView);
