import {StyleSheet} from 'react-native';
import colors from '../../../constants/colors';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  logo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: 150,
    height: 100,
    marginLeft: 'auto',
  },
  linkWrapper: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-around',
    // marginTop: 30,
  },
  linkContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  linkIcon: {
    fontSize: 14,
    marginRight: 4,
    color: colors.primary,
  },
  linkText: {
    fontSize: 14,
    color: colors.primary,
  },
  signUpTextContainer: {
    fontSize: 18,
    color: colors.black,
    textAlign: 'center',
    marginVertical: 28,
  },
  signUpText: {
    fontWeight: 'bold',
    color: colors.primary,
  },
});

export default styles;
