import { StyleSheet } from 'react-native';
import colors from '../../constants/colors';

const styles = StyleSheet.create({
  container: {},
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  label: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.black,
    width: 180,
  },
  desc: {
    fontSize: 18,
    color: colors.black,
    flex: 1,
  },
  list: {
    marginBottom: 8,
  },
  listContent: {
    marginTop: 8,
  },
  cardInfo: {
    alignItems: 'center',
    marginBottom: 12,
  },
  username: {
    fontSize: 28,
    fontWeight: 'bold',
    color: colors.black,
    marginTop: 18,
  },
  share: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 16,
  },
  shareText: {
    fontSize: 18,
    fontWeight: 'bold',
    marginLeft: 8,
    color: colors.primary,
  },
  btnWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 54,
  },
});

export default styles;
