import { Alert, Platform } from 'react-native';

const alertPolyfill = (title, description, options = [], extra) => {
  const titleDescription = [title, description].filter(Boolean).join('\n');

  if (options?.length <= 1) {
    alert(`${titleDescription}`);
    if (options[0]?.onPress) {
      options[0]?.onPress?.();
    }
  } else {
    const result = window.confirm(titleDescription);

    if (result) {
      const confirmOption = options.find(({ style }) => style !== 'cancel');
      confirmOption && confirmOption.onPress();
    } else {
      const cancelOption = options.find(({ style }) => style === 'cancel');
      cancelOption && cancelOption.onPress();
    }
  }
};

export const CustomAlert = Platform.OS === 'web' ? alertPolyfill : Alert.alert;
