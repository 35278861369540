import React from 'react';
import { SafeAreaView, ScrollView, View, Text, Image } from 'react-native';
import logoImg from '../../../../assets/images/logo.png';
import styles from './styles';

const Layout = ({ title, children }) => {
  return (
    <SafeAreaView style={styles.container}>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={styles.wrapper}
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.logo}>
          <Image source={logoImg} />
        </View>

        <View style={styles.titleContainer}>
          <Text style={styles.titleText}>{title}</Text>
        </View>

        {children}
      </ScrollView>
    </SafeAreaView>
  );
};

export default React.memo(Layout);
