import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { View, SafeAreaView, ScrollView, Text, Platform } from 'react-native';
import _ from 'lodash';
import StoreOverview from '../../components/StoreOverview';
import { DEVICE_WIDTH } from '../../constants/size';
import { SCREEN_NAME } from '../../constants/screens';
import BoxImage from '../../components/BoxImage';
import BoxCollapse from './components/BoxCollapse';
import ButtonOpenCamera from './components/ButtonOpenCamera';
import { useGetCameraList } from '../../api/cameraApi';
import { useCreateImage, useDeleteImage } from '../../api/imageApi';
import { usePlan } from '../../api/planApi';
import { getCurrentUser } from '../../store/slices/authSlice';
import * as ImageManipulator from 'expo-image-manipulator';

const CameraList = ({ navigation, route }) => {
  const { planId } = route.params;
  const currentUser = useSelector(getCurrentUser);
  const createImageMutation = useCreateImage();
  const deleteImageMutation = useDeleteImage();

  const { data, isLoading, isError, refetch } = usePlan({
    params: { plan_id: planId },
    options: {
      select: (result) => {
        const imagesByShelvesId = _.reduce(
          result?.store_image,
          (acc, curr) => {
            if (!acc[curr.shelves_id]) {
              acc[curr.shelves_id] = [];
            }

            acc[curr.shelves_id].push(curr);

            return acc;
          },
          {}
        );

        return {
          ...result,
          imagesByShelvesId,
        };
      },
    },
  });

  const { data: cameraList } = useGetCameraList({
    options: {
      enabled: !!data?.plan_id,
      select(response) {
        return response?.camera ?? [];
      },
    },
  });

  const handleAddImage = async (id, imageOrg) => {
    console.log({ id, imageOrg });

    const image = await ImageManipulator.manipulateAsync(
      imageOrg.uri,
      [{ resize: { width: 1080 } }],
      { compress: 0.5, format: ImageManipulator.SaveFormat.JPEG }
    );

    console.log({ id, image });

    const localUri = image.uri;
    const filename = localUri.split('/').pop();
    const match = /\.(\w+)$/.exec(filename);
    const type = match ? `image/${match[1]}` : `image`;

    const formData = new FormData();
    formData.append('user_id', currentUser.user_id);
    formData.append('plan_id', planId);
    formData.append('manual', 0);
    formData.append('shelves_id', id);
    formData.append('store_code', data.store_code);
    formData.append('create_on', new Date().getTime());
    formData.append('month', data.date_start_year_month);
    let flag_web = Platform.OS === 'web';
    if (flag_web) {
      let image_blob = await fetch(image.uri).then((res) => res.blob());
      formData.append('image', image_blob);
    } else {
      formData.append('image', {
        uri: localUri,
        name: filename,
        type: type,
      });
    }

    createImageMutation.mutate(formData, {
      onSuccess(response) {
        console.log({ response });
        // Call API success and refetch
        refetch();
      },
    });
  };

  const handleDeleteImage = (id) => {
    deleteImageMutation.mutate(
      { plan_id: planId, id },
      {
        onSuccess() {
          refetch();
        },
      }
    );
  };

  if (isLoading) {
    return null;
  }

  if (isError || !data?.plan_id) {
    return null; // Show error
  }

  return (
    <SafeAreaView className='flex-1 bg-white'>
      <ScrollView className='flex-1'>
        <View>
          <StoreOverview
            name={data.store_name}
            address={data.address_full}
            planName={data.plan_name}
            store_code={data.store_code}
            working_time={data.working_time}
          />
        </View>

        <View className='h-20'>{/* Fix scroll to bottom */}</View>
        <View className='mt-[34px] mx-5'>
          {cameraList?.length > 0 &&
            cameraList.map(({ id, name }) => (
              <BoxCollapse key={id} title={name}>
                <View
                  style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  {data.imagesByShelvesId[id]?.length > 0 &&
                    data.imagesByShelvesId[id]?.map((item, index) => {
                      // console.log({ item });
                      // 3 columns with space = 20 (78)
                      const width = (DEVICE_WIDTH - 118) / 3;

                      return (
                        <BoxImage
                          styleContainer={{
                            marginRight: index % 3 === 2 ? 0 : 20,

                            marginBottom: index % 3 === 0 ? 0 : 20,
                          }}
                          styleImage={{
                            width,
                            height: width,
                          }}
                          link={item.link}
                          key={item.id}
                          onDelete={() => handleDeleteImage(item.id)}
                        />
                      );
                    })}

                  {/* Check maximum images */}
                  <ButtonOpenCamera
                    style={{
                      width: (DEVICE_WIDTH - 118) / 3,
                      height: (DEVICE_WIDTH - 118) / 3,
                    }}
                    onPress={() =>
                      navigation.navigate(SCREEN_NAME.CAMERA, {
                        callback: (image) => handleAddImage(id, image),
                      })
                    }
                  />
                </View>
              </BoxCollapse>
            ))}
        </View>
        <View className='h-60'>{/* Fix scroll to bottom */}</View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default React.memo(CameraList);
