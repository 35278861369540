import { StyleSheet } from 'react-native';
import colors from '../../constants/colors';

const styles = StyleSheet.create({
  container: {
    paddingVertical: 34,
    marginHorizontal: 8,
    marginBottom: 30,
    borderBottomWidth: 3,
    borderColor: colors.primary,
  },
  content: {
    paddingHorizontal: 30,
  },
  text: {
    fontSize: 16,
    color: colors.black,
    marginTop: 8,
  },
  name: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.primary,
    marginTop: 0,
  },
  date: {
    fontWeight: 'bold',
    color: colors.red,
  },
});

export default styles;
