import React, { useEffect, useState, useRef } from 'react';
import {
  SafeAreaView,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
} from 'react-native';
import { Camera, CameraType, FlashMode } from 'expo-camera';
// import * as MediaLibrary from 'expo-media-library';
import { Entypo, Feather } from '@expo/vector-icons';

import colors from '../../constants/colors';

const CameraScreen = ({ route, navigation }) => {
  const { callback } = route.params;

  const [hasPermissionCamera, setHasPermissionCamera] = useState();
  // const [hasMediaLibraryPermission, setHasMediaLibraryPermission] = useState();
  const [image, setImage] = useState(null);
  const cameraRef = useRef(null);
  const [type, setType] = useState(CameraType.back);
  const [flashMode, setFlashMode] = useState(FlashMode.off);
  const [isCameraReady, setIsCameraReady] = useState(false);

  const onCameraReady = () => {
    console.log('onCameraReady');
    setIsCameraReady(true);
  };

  useEffect(() => {
    (async () => {
      // const cameraPermission = await Camera.requestCameraPermissionsAsync();
      const cameraPermission = {'status':'granted'};
      // const mediaPermission = await MediaLibrary.requestPermissionsAsync();
      const mediaPermission = {'status':'granted'};

      setHasPermissionCamera(cameraPermission.status === 'granted');
      // setHasMediaLibraryPermission(mediaPermission.status === 'granted');
    })();
  }, []);

  const handleTakePicture = async () => {
    if (cameraRef) {
      try {
        const options = {
          quality: 0.5,
          base64: false,
          exif: false,
        };

        const data = await cameraRef.current.takePictureAsync(options);
        setImage(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleTakePictureAgain = () => {
    setImage(null);
  };

  const handleSave = () => {
    if (image) {
      callback(image);
      navigation.goBack();
    }
  };

  const handleToggleCameraType = () => {
    setType((current) =>
      current === CameraType.back ? CameraType.front : CameraType.back
    );
  };

  if (hasPermissionCamera === undefined) {
    return <Text>Requesting permission...</Text>;
  } else if (!hasPermissionCamera) {
    return (
      <Text>
        Permission for camera not granted. Please change this in settings.
      </Text>
    );
  }

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.container}>
        {!image ? (
          <Camera
            style={styles.camera}
            type={type}
            flashMode={flashMode}
            ref={cameraRef}
            onCameraReady={onCameraReady}
            onMountError={(error) => {
              console.log("cammera error", error);
            }}
          />
        ) : (
          <Image source={{ uri: image.uri }} style={styles.camera} />
        )}

        <View style={styles.action}>
          {!image ? (
            <>
              <TouchableOpacity
                style={styles.takePhotoButton}
                disabled={!isCameraReady}
                onPress={handleTakePicture}
                activeOpacity={0.8}
              >
                <Entypo name='camera' size={40} color='black' />
              </TouchableOpacity>

              <TouchableOpacity
                style={[styles.takePhotoButton, styles.toggleCamera]}
                disabled={!isCameraReady}
                onPress={handleToggleCameraType}
                activeOpacity={0.8}
              >
                <Feather name='refresh-cw' size={24} color='black' />
              </TouchableOpacity>
            </>
          ) : (
            <>
              <TouchableOpacity
                style={styles.takePhotoButton}
                disabled={!isCameraReady}
                onPress={handleSave}
                activeOpacity={0.8}
              >
                <Entypo name='check' size={40} color='black' />
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.takePhotoButton}
                disabled={!isCameraReady}
                onPress={handleTakePictureAgain}
                activeOpacity={0.8}
              >
                <Entypo name='retweet' size={40} color='black' />
              </TouchableOpacity>
            </>
          )}
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  camera: {
    flex: 1,
  },
  action: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  takePhotoButton: {
    width: 80,
    height: 80,
    borderRadius: 40,
    backgroundColor: colors.white,
    shadowColor: colors.black,
    elevation: 3,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 5,
  },
  toggleCamera: {
    width: 50,
    height: 50,
    borderRadius: 25,
    position: 'absolute',
    right: 20,
    zIndex: 100,
  },
});

export default React.memo(CameraScreen);
