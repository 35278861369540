import React from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import colors from '../../constants/colors';

const BoxImage = ({
  styleContainer,
  styleImage,
  link,
  canDelete = true,
  onDelete = () => {},
}) => {
  const onPressPreview = () => {};

  return (
    <View style={[styles.container, styleContainer]}>
      <TouchableOpacity activeOpacity={0.8} onPress={onPressPreview}>
        {canDelete && (
          <TouchableOpacity
            style={styles.deleteIcon}
            activeOpacity={0.8}
            onPress={onDelete}
          >
            <Ionicons name='md-close' size={24} color={colors.white} />
          </TouchableOpacity>
        )}
        <Image
          style={[
            styles.image,
            styleImage ? styleImage : { width: 100, height: 100 },
          ]}
          source={{
            uri: link,
          }}
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  image: {
    borderRadius: 5,
  },
  deleteIcon: {
    width: 35,
    height: 35,
    borderRadius: 17.5,
    backgroundColor: colors.red,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: -10,
    right: -10,
    zIndex: 2,
  },
});

export default React.memo(BoxImage);
