import {
  View,
  Text,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Platform,
} from 'react-native';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import GlobalStyles from '../../components/globalStyles/GlobalStyles';
import Button from '../../components/Button';
import Modal from 'react-native-modal';

import { uploadOderApi } from '../../api/uploadOderApi';
import ButtonOpenCamera from '../camera/components/ButtonOpenCamera';
import { DEVICE_WIDTH } from '../../constants/size';
import { SCREEN_NAME } from '../../constants/screens';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/slices/authSlice';
import BoxImage from '../../components/BoxImage';
import { uploadImageOder } from '../../api/uploadImageOderApi';

import { useForm, Controller } from 'react-hook-form';
import TextInput, { TextInputCustom } from '../../components/TextInput';
import Select from '../../components/Select';
import colors from '../../constants/colors';
import { useGetProducts } from '../../api/productApi';
import { useGetGifts } from '../../api/giftApi';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ProductItem from './components/ProductItem';
import { CustomAlert } from '../../components/CustomAlert';
import * as ImageManipulator from 'expo-image-manipulator';

const schema = yup.object().shape({
  order_tkh: yup.string().required('Bắt buộc'),
  order_sdt: yup.string().required('Bắt buộc'),
  images: yup
    .array()
    .min(1, 'Bắt buộc')
    .max(3, 'Hình chụp tối đa 3 tấm')
    .required('Bắt buộc'),
});

const AddOrderScreen = ({ route, navigation }) => {
  const { planId } = route.params;
  const {
    control,
    handleSubmit: handleSubmit1,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      sample: null,
      images: [],
      children: '',
    },
  });
  const images = watch('images');
  const { data: products = [] } = useGetProducts();
  const { data: gifts = [] } = useGetGifts();
  const uploadOderMutation = uploadOderApi({ params: { plan_id: planId } });
  const uploadImageOderMutation = uploadImageOder();
  const [isModalVisible, setModalVisible] = useState(false);
  const currentUser = useSelector(getCurrentUser);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  useEffect(() => {
    if (products.length > 0) {
      Array.from({ length: 5 }, (v, index) => {
        setValue(`order_sanpham.${index}.id`, products[0].attr_id);
        setValue(`order_sanpham.${index}.value`, '0');
        setValue(`order_sanpham.${index}.price`, products[0].price);
      });
    }

    if (gifts.length > 0) {
      Array.from({ length: 2 }, (v, index) => {
        setValue(`gifts.${index}.id`, gifts[0].id);
        setValue(`gifts.${index}.value`, '0');
      });
    }
  }, [products, gifts]);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const onSubmit = ({ images, ...values }) => {
    // console.log({ images, ...values });
    const data = {
      user_id: currentUser?.user_id,
      plan_id: planId,
      hoadon_id: 0,
      ...values,
      gifts: JSON.stringify(values.gifts),
      order_sanpham: JSON.stringify(
        _.map(values.order_sanpham, (item) => ({
          ...item,
          nhom_level1: 4,
          nhom_level2: 72,
        }))
      ),
    };

    console.log({ data });
    setIsLoadingSubmit(true);
    uploadOderMutation.mutate(data, {
      onSuccess: async (response) => {
        try {
          await Promise.all(
            images.map(async (imageOrg) => {
              console.log({ imageOrg });

              const image = await ImageManipulator.manipulateAsync(
                imageOrg.uri,
                [{ resize: { width: 1080 } }],
                { compress: 0.5, format: ImageManipulator.SaveFormat.JPEG }
              );

              console.log({ image });

              const localUri = image.uri;
              const filename = localUri.split('/').pop();
              const match = /\.(\w+)$/.exec(filename);
              const type = match ? `image/${match[1]}` : `image`;

              const formData = new FormData();
              formData.append('user_id', currentUser?.user_id);
              formData.append('plan_id', planId);
              formData.append('manual', 0);
              formData.append('order_id', response);
              formData.append('create_on', new Date().getTime());

              let flag_web = Platform.OS === 'web';
              if (flag_web) {
                let image_blob = await fetch(image.uri).then((res) =>
                  res.blob()
                );
                formData.append('image', image_blob);
              } else {
                formData.append('image', {
                  uri: localUri,
                  name: filename,
                  type: type,
                });
              }

              return uploadImageOderMutation.mutateAsync(formData);
            })
          );
          setIsLoadingSubmit(false);
          CustomAlert('Nhập báo cáo thành công', '', [
            { text: 'Đồng ý', onPress: () => navigation.goBack() },
          ]);
        } catch (error) {
          console.log(error);
          setIsLoadingSubmit(false);
          CustomAlert('Đã có lỗi xảy ra', 'Vui lòng thử lại.', [
            { text: 'Đóng', onPress: () => {} },
          ]);
        }
      },
    });
  };

  const handleAddImage = (image) => {
    setValue('images', [...images, image]);
  };

  const handleDeleteImage = (imageIndex) => {
    setValue(
      'images',
      images.filter((item, index) => index !== imageIndex)
    );
  };

  return (
    <SafeAreaView style={GlobalStyles.droidSafeArea}>
      <ScrollView>
        <View className='px-[25px] pb-7'>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Hình ảnh</Text>
            <View style={styles.sectionContent}>
              <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {images.length > 0 &&
                  images.map((item, index) => {
                    // 3 columns with space = 20 (78)
                    const width = (DEVICE_WIDTH - 118) / 4;

                    return (
                      <BoxImage
                        styleContainer={{
                          marginRight: index % 4 === 3 ? 0 : 20,
                          marginBottom: index % 4 === 0 ? 0 : 20,
                        }}
                        styleImage={{
                          width,
                          height: width,
                        }}
                        link={item.uri}
                        key={index}
                        onDelete={() => handleDeleteImage(index)}
                      />
                    );
                  })}

                {images.length < 3 && (
                  <ButtonOpenCamera
                    style={{
                      width: (DEVICE_WIDTH - 118) / 4,
                      height: (DEVICE_WIDTH - 118) / 4,
                    }}
                    onPress={() =>
                      navigation.navigate(SCREEN_NAME.CAMERA, {
                        callback: handleAddImage,
                      })
                    }
                  />
                )}
              </View>
              {errors.images && (
                <Text style={{ color: colors.red }}>
                  {errors.images?.message}
                </Text>
              )}
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Mua hàng</Text>

            <View style={styles.sectionContent}>
              {Array.from({ length: 5 }, (v, index) => {
                return (
                  <ProductItem
                    key={index}
                    index={index}
                    control={control}
                    options={_.map(products, (item) => ({
                      label: item.name,
                      value: item.attr_id,
                      price: item.price,
                    }))}
                    onChangePrice={(name, value) => setValue(name, value)}
                  />
                );
              })}
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Quà tặng</Text>

            <View style={styles.sectionContent}>
              {Array.from({ length: 2 }, (v, index) => {
                return (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      flex: 1,
                    }}
                    key={index}
                  >
                    <Text
                      style={{
                        color: colors.black,
                        fontSize: 16,
                        marginRight: 10,
                      }}
                    >
                      Quà tặng {index + 1}
                    </Text>

                    <View style={{ flexDirection: 'row', flex: 1 }}>
                      <View style={{ flex: 1 }}>
                        <Controller
                          name={`gifts.${index}.id`}
                          control={control}
                          render={({ field }) => {
                            return (
                              <Select
                                {...field}
                                style={{ borderRadius: 0, width: '100%' }}
                                options={_.map(gifts, (item) => ({
                                  label: item.name,
                                  value: item.id,
                                }))}
                              />
                            );
                          }}
                        />
                      </View>
                      <View>
                        <Controller
                          name={`gifts.${index}.value`}
                          control={control}
                          render={({ field }) => {
                            return (
                              <TextInputCustom
                                {...field}
                                inputStyles={{
                                  width: 40,
                                  borderRadius: 0,
                                  height: '100%',
                                }}
                                keyboardType='numeric'
                              />
                            );
                          }}
                        />
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Thông tin Khách hàng</Text>

            <View style={styles.sectionContent}>
              <View style={styles.formGroup}>
                <Controller
                  name='order_tkh'
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextInput
                        {...field}
                        label='Họ & tên'
                        error={errors?.order_tkh}
                        isRequired
                      />
                    );
                  }}
                />
              </View>

              <View style={styles.formGroup}>
                <Controller
                  name='order_sdt'
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextInput
                        {...field}
                        label='Số điện thoại'
                        keyboardType='numeric'
                        error={errors?.order_sdt}
                        isRequired
                      />
                    );
                  }}
                />
              </View>

              <View style={styles.formGroup}>
                <View style={{ marginBottom: 8 }}>
                  <Text style={{ fontSize: 16, color: '#000' }}>
                    Con thứ mấy?
                  </Text>
                </View>
                <Controller
                  name='children'
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        options={[
                          { label: '', value: '' },
                          { label: 'Con đầu', value: 'Con đầu' },
                          { label: 'Con thứ 2', value: 'Con thứ 2' },
                          {
                            label: 'Con thứ 3 trở lên',
                            value: 'Con thứ 3 trở lên',
                          },
                        ]}
                        style={{ width: '100%' }}
                      />
                    );
                  }}
                />
              </View>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Ghi nhận thông tin</Text>

            <View style={styles.sectionContent}>
              <View style={styles.formGroup}>
                <Controller
                  name='feedback'
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextInput
                        {...field}
                        multiline={true}
                        numberOfLines={3}
                      />
                    );
                  }}
                />
              </View>
            </View>
          </View>

          <View className='flex mx-auto mt-3'>
            {!isLoadingSubmit && (
              <Button
                onPress={handleSubmit1(onSubmit)}
                disabled={isLoadingSubmit}
              >
                Submit
              </Button>
            )}
          </View>
        </View>
      </ScrollView>

      <View>
        <Modal isVisible={isModalVisible}>
          <View className=' rounded-xl bg-slate-50  '>
            <View className='rounded-t-xl py-2 bg-[#007FC3] '>
              <Text className='text-center text-base text-white font-semibold'>
                Hoàn thành báo cáo
              </Text>
            </View>

            <View className='py-8'>
              <Text className='text-base font-normal text-center px-6'>
                Bạn có chắc chắn dữ liệu nhập vào đã chính xác?
              </Text>
            </View>

            <View className=' flex-row justify-center items-center mb-7 space-x-4'>
              <View>
                <Button onPress={() => {}}>Xác nhận</Button>
              </View>
              <View>
                <Button onPress={toggleModal} outline={true}>
                  Trở lại
                </Button>
              </View>
            </View>
          </View>
        </Modal>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  section: {
    marginTop: 18,
  },
  sectionTitle: {
    color: colors.primary,
    fontSize: 16,
    fontWeight: 'bold',
  },
  sectionContent: {
    marginTop: 8,
  },
  formGroup: {
    marginBottom: 18,
  },
});

export default AddOrderScreen;
