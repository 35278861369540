import React from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import styles from './styles';

const WorkingItem = ({
  icon: Icon,
  name,
  onPress,
  disabled = false,
  isOk = false,
}) => {
  const disabledStyles = disabled ? { backgroundColor: '#898F93' } : {};

  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disabled}
      activeOpacity={0.8}
      style={[styles.container]}
    >
      {isOk && (
        <View style={styles.checkedIcon}>
          <FontAwesome name='check-circle' size={40} color='green' />
        </View>
      )}
      <View style={[styles.icon, disabledStyles]}>
        <Icon width={38} />
      </View>
      <Text style={styles.name}>{name}</Text>
    </TouchableOpacity>
  );
};

export default React.memo(WorkingItem);
