import _ from 'lodash';

export const sumTotalValueWithDuplicateKey = (array = [], key) => {
  return _.chain(array)
    .reduce((obj, current) => {
      if (!obj[current[key]]) {
        obj[current[key]] = current;
      } else {
        obj[current[key]].value = +obj[current[key]].value + +current.value;
      }

      return obj;
    }, {})
    .value();
};
