import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import colors from '../../constants/colors';

const ProfileInfo = ({ user }) => {
  return (
    <View style={styles.container}>
      <FontAwesome name='user-circle' size={60} color={colors.primary} />

      <Text style={styles.user_name}>{user?.fullname}</Text>
      <Text style={styles.user_code}>{user?.user_code}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  user_name: {
    fontSize: 28,
    fontWeight: 'bold',
    color: colors.black,
  },
  user_code: {
    color: colors.black,
    fontSize: 16,
  },
});

export default React.memo(ProfileInfo);
