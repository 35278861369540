import { useEffect, useState } from 'react';
import { useCheckAppVersion } from '../api/authApi';
import { APP_VERSION } from '../config/app';
import { CustomAlert } from '../components/CustomAlert';

const withCheckAppVersion = (WrappedComponent) => (props) => {
  const [isChecking, setIsChecking] = useState();
  const checkAppVersionMutation = useCheckAppVersion();

  useEffect(() => {
    checkAppVersionMutation.mutate(
      { version: APP_VERSION },
      {
        onSuccess(response) {
          setIsChecking(!!response);
        },
      }
    );
  }, []);

  if (isChecking === undefined) {
    return null;
  }

  if (!isChecking) {
    return CustomAlert('Vui lòng update app mới nhất.', '', []);
  }

  return <WrappedComponent {...props} />;
};

export default withCheckAppVersion;
