import { useQuery, useMutation } from "@tanstack/react-query";
import axiosClient from './axiosClient';


const uploadImageOderApi = {
    upload(formData) {
        const config = {
            method: "post",
            url: "/home/upload_images_orders",
            // responseType: "json",
            headers: {
                'Content-Type': 'multipart/form-data',
                // if backend supports u can use gzip request encoding
                // "Content-Encoding": "gzip",
            },
            transformRequest: (data, headers) => {
                // !!! override data to return formData
                // since axios converts that to string
                return formData;
            },
            onUploadProgress: (progressEvent) => {
                // use upload data, since it's an upload progress
                // iOS: {"isTrusted": false, "lengthComputable": true, "loaded": 123, "total": 98902}
            },
            data: formData,
        };
        
        // send post request and get response
        return axiosClient.request(config);
    
        // return axiosClient.post("/home/upload_images_orders", data,
        //     {
        //         headers: {
        //             'Content-Type': 'multipart/form-data',
        //         },
        //     }
        // )
    }
}

export const uploadImageOder = () => {
    return useMutation({
        mutationFn: uploadImageOderApi.upload,
        retry: 3,
    });
}