import React, { useEffect } from 'react';
import { Text, View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { createStackNavigator } from '@react-navigation/stack';
import {
  getIsLoggedIn,
  doLogout,
  getLoginDate,
} from '../store/slices/authSlice';
import { SCREEN_NAME } from '../constants/screens';
import SignIn from './auth/SignIn';
import PlanList from './plans/PlanList';
import WorkingView from './working/WorkingView';
import MapContainer from './map/MapContainer';
import PhotoList from './camera';
import Camera from './camera/Camera';
import ConfirmInfoView from './confirm-info/ConfirmInfoView';
import AddOrderScreen from './order';
import colors from '../constants/colors';
import { APP_VERSION } from '../config/app';
import { useNavigation } from '@react-navigation/native';
import { formatTime } from '../utils/timeUtils';
import withCheckAppVersion from '../hocs/withCheckAppVersion';
import SignUp from './auth/SignUp';
import Profile from './profile/Profile';
import Orders from './order/Orders';
import ChangePassword from './account-settings/change-password';

const Stack = createStackNavigator();

const HeaderTitle = ({ children }) => {
  return (
    <View style={{ alignItems: 'center' }}>
      <Text
        style={{
          fontSize: 20,
          fontWeight: 'bold',
          color: colors.primary,
          alignItems: 'center',
        }}
      >
        {children}
      </Text>
      <Text style={{ color: colors.black, fontWeight: 'bold' }}>
        {APP_VERSION}
      </Text>
    </View>
  );
};

const Screens = () => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const loginDate = useSelector(getLoginDate);
  const navigation = useNavigation();
  const dispatch = useDispatch();

  useEffect(() => {
    const logoutInterval = setInterval(() => {
      const isLogout = formatTime(new Date()) !== loginDate;

      if (!loginDate) {
        return;
      }

      if (isLogout) {
        navigation.reset({
          index: 0,
          routes: [{ name: SCREEN_NAME.SIGN_IN }],
        });
        dispatch(doLogout());
      }
    }, 2000);

    return () => {
      clearInterval(logoutInterval);
    };
  }, [loginDate]);

  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: colors.white,
          shadowColor: colors.primary,
          shadowOffset: {
            width: 0,
            height: 4,
          },
          shadowOpacity: 0.3,
          shadowRadius: 4,
          elevation: 4,
        },
        headerTintColor: colors.primary,
        headerTitleAlign: 'center',
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerTitle: (props) => <HeaderTitle {...props} />,
      }}
      initialRouteName={
        isLoggedIn ? SCREEN_NAME.PLAN_LIST : SCREEN_NAME.SIGN_IN
      }
    >
      <Stack.Screen
        name={SCREEN_NAME.SIGN_IN}
        component={SignIn}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name={SCREEN_NAME.PLAN_LIST}
        component={PlanList}
        options={{
          title: 'Danh sách ca làm việc',
        }}
      />
      <Stack.Screen
        name={SCREEN_NAME.WORKING}
        component={WorkingView}
        options={{
          title: 'Ca làm việc',
        }}
      />
      <Stack.Screen
        name={SCREEN_NAME.PHOTO_LIST}
        component={PhotoList}
        options={{
          title: 'Chụp hình',
        }}
      />
      <Stack.Screen
        name={SCREEN_NAME.CAMERA}
        component={Camera}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name={SCREEN_NAME.MAP}
        component={MapContainer}
        options={({ route }) => {
          const { isCheckout } = route.params;

          return {
            title: isCheckout ? 'Check out' : 'Check in',
          };
        }}
      />
      <Stack.Screen
        name={SCREEN_NAME.CONFIRM_INO}
        component={ConfirmInfoView}
        options={{ title: 'Xác nhận kết quả ngày (ca)' }}
      />
      <Stack.Screen
        name={SCREEN_NAME.ADD_ORDER}
        component={AddOrderScreen}
        options={{ title: 'Nhập báo cáo' }}
      />

      <Stack.Screen
        name={SCREEN_NAME.SIGN_UP}
        component={SignUp}
        options={{ headerShown: false }}
      />

      <Stack.Screen
        name={SCREEN_NAME.PROFILE}
        component={Profile}
        options={{ title: 'Thông tin cá nhân' }}
      />

      <Stack.Screen
        name={SCREEN_NAME.ORDERS}
        component={Orders}
        options={{ title: 'Nhập báo cáo' }}
      />

      <Stack.Screen
        name={SCREEN_NAME.CHANGE_PASSWORD}
        component={ChangePassword}
        options={{ title: 'Đổi mật khẩu' }}
      />
    </Stack.Navigator>
  );
};

export default withCheckAppVersion(Screens);
