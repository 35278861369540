import React from 'react';
import { View, ActivityIndicator, StyleSheet } from 'react-native';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import colors from '../../constants/colors';

const Loading = () => {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  return (
    <>
      {isFetching || isMutating ? (
        <View style={[styles.container]}>
          <View style={styles.loadingContainer}>
            <ActivityIndicator size='large' color={colors.primary} />
          </View>
        </View>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    position: 'absolute',
  },
  loadingContainer: {
    width: 100,
    height: 100,
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
});

export default React.memo(Loading);
