import { StyleSheet } from 'react-native';
import colors from '../../../constants/colors';

const styles = StyleSheet.create({
  formGroup: {
    marginBottom: 16,
  },
  infoTextContainer: {
    fontSize: 18,
    color: colors.black,
    textAlign: 'center',
    marginVertical: 16,
  },
  infoText: {
    fontWeight: 'bold',
    color: colors.primary,
  },
});

export default styles;
