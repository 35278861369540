import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  SafeAreaView,
  View,
  Image,
  Text,
  Pressable,
  TouchableOpacity,
  Linking,
} from "react-native";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import Title from "../../../components/Title";
import styles from "./styles";
import { useTogglePasswordVisibility } from "../../../hooks/useTogglePasswordVisibility";
import { MaterialCommunityIcons, Feather } from "@expo/vector-icons";

import { doLogin } from "../../../store/slices/authSlice";
import { SCREEN_NAME } from "../../../constants/screens";

import { Platform } from "react-native";
import * as MediaLibrary from "expo-media-library";

import * as Location from "expo-location";
import { Camera, CameraType } from "expo-camera";
import { Link } from "@react-navigation/native";
import { CustomAlert } from "../../../components/CustomAlert";
import colors from "../../../constants/colors";
import { APP_VERSION } from "../../../config/app";

const SignIn = ({ navigation }) => {
  const dispatch = useDispatch();

  const [inputs, setInputs] = useState({});
  const [checkLoginError, setCheckLoginError] = useState(false);
  const [cameraDenied, setCameraDenied] = useState(false);
  const [locationDenied, setLocationDenied] = useState(false);
  const [storageDenied, setStorageDenied] = useState(false);
  const [loginAllowed, setLoginAllowed] = useState(false);
  // const [permission, requestPermission] = Camera.useCameraPermissions();
  // console.log('permission', permission);

  const { passwordVisibility, rightIcon, handlePasswordVisibility } =
    useTogglePasswordVisibility();

  const handleSubmit = async () => {
    const data = {
      user: inputs?.user,
      pass: inputs?.pass,
    };

    try {
      const result = await dispatch(doLogin(data));
      unwrapResult(result);

      navigation.reset({
        index: 0,
        routes: [{ name: SCREEN_NAME.PLAN_LIST }],
      });
    } catch (error) {
      console.log({ error });
      setCheckLoginError(true);
    }
  };

  const handleChange = (text, input) => {
    setInputs((prevState) => ({ ...prevState, [input]: text }));
  };

  const requestPermissions = async () => {
    try {
      let flag_granted = false;
      let granted;
      let status;

      ({ status } = await Camera.requestCameraPermissionsAsync());
      granted = status;
      console.log("granted camera", granted);
      if (granted === "granted") {
        flag_granted = true;
        setCameraDenied(false);
      } else {
        flag_granted = false;
        setCameraDenied(true);
        CustomAlert("Please reopen the app and allow camera permission");
      }

      ({ status } = await Location.requestForegroundPermissionsAsync());
      granted = status;
      console.log("granted location", granted);
      if (granted === "granted") {
        flag_granted = true;
        setLocationDenied(false);
      } else {
        flag_granted = false;
        setLocationDenied(true);

        CustomAlert("Please reopen the app and allow gps/location permission");
      }

      // Storage permission
      if (Platform.OS != "web") {
        ({ status } = await MediaLibrary.requestPermissionsAsync());
        granted = status;
        console.log("granted storage", granted);
        if (granted === "granted") {
          flag_granted = true;
          setStorageDenied(false);
        } else {
          flag_granted = false;
          setStorageDenied(true);

          CustomAlert("Please reopen the app and allow storage permission");
        }
      }

      setLoginAllowed(flag_granted);
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    requestPermissions();
  }, []);

  return (
    <SafeAreaView style={styles.container}>
      <View style={{ padding: 24 }}>
        <View style={styles.logo}>
          <Image source={require("../../../assets/images/logo.png")} />
        </View>

        <Text
          style={{
            color: colors.black,
            fontWeight: "bold",
            textAlign: "center",
            marginTop: 30,
          }}
        >
          {APP_VERSION}
        </Text>

        <Text
          style={{
            fontSize: 28,
            fontWeight: "bold",
            color: colors.black,
            marginBottom: 40,
            marginTop: 40,
            textAlign: "center",
          }}
        >
          Login
        </Text>

        <Input
          label="Username"
          onChangeText={(text) => handleChange(text, "user")}
        />

        <View className="relative">
          <Input
            label="Password"
            secureTextEntry={passwordVisibility}
            onChangeText={(text) => handleChange(text, "pass")}
          />
          <Pressable
            className="absolute right-4 bottom-7"
            onPress={handlePasswordVisibility}
          >
            <MaterialCommunityIcons
              name={rightIcon}
              size={22}
              color="#232323"
            />
          </Pressable>
        </View>

        {checkLoginError && (
          <Text className="text-red-600 text-center">
            You have entered an invalid username or password
          </Text>
        )}
        {locationDenied && (
          <Text className="text-red-600 text-center">
            Bạn chưa cấp quyền location GPS
          </Text>
        )}
        {cameraDenied && (
          <Text className="text-red-600 text-center">
            Bạn chưa cấp quyền camera
          </Text>
        )}
        {storageDenied && (
          <Text className="text-red-600 text-center">
            Bạn chưa cấp quyền storage (bộ nhớ lưu trữ)
          </Text>
        )}

        <View style={{ marginTop: 20 }}>
          <Button onPress={handleSubmit} disabled={!loginAllowed}>
            Login
          </Button>
        </View>

        <Text style={styles.signUpTextContainer}>
          Chưa có tài khoản.{" "}
          <Link to={{ screen: SCREEN_NAME.SIGN_UP }}>
            <Text style={styles.signUpText}>Đăng ký ngay</Text>
          </Link>
        </Text>

        <View style={styles.linkWrapper}>
          <TouchableOpacity
            style={styles.linkContainer}
            onPress={() =>
              Linking.openURL(
                "https://cps.webdigital.vn/activation_training_process.pdf" // Move to constants
              )
            }
          >
            <Feather name="download" style={styles.linkIcon} />
            <Text style={styles.linkText}>Training Process</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.linkContainer}
            onPress={() =>
              Linking.openURL(
                "https://cps.webdigital.vn/product_pg_tranining.pdf" // Move to constants
              )
            }
          >
            <Feather name="download" style={styles.linkIcon} />
            <Text style={styles.linkText}>PG Training</Text>
          </TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default React.memo(SignIn);
