import React from 'react';
import { TouchableOpacity, Text, View } from 'react-native';
import LogoutIcon from '../../assets/images/logout.svg';
import styles from './styles';

const ActionButton = ({ icon, children, ...restProps }) => {
  return (
    <TouchableOpacity style={styles.container} {...restProps}>
      {icon && <View style={styles.icon}>{icon}</View>}
      <Text style={styles.name}>{children}</Text>
    </TouchableOpacity>
  );
};

export default React.memo(ActionButton);
