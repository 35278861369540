import { useQuery } from '@tanstack/react-query';
import axiosClient from './axiosClient';

const giftApi = {
  getAll(params) {
    return axiosClient.get('/home/list_gift', { params });
  },
};

export const useGetGifts = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['gifts', params],
    queryFn: () => giftApi.getAll(params),
    ...options,
  });
};
