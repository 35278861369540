import { StyleSheet } from 'react-native';
import colors from '../../../../constants/colors';

const styles = StyleSheet.create({
  container: {
    shadowColor: colors.black,
    shadowOpacity: 0.25,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 4,
    elevation: 4,
    backgroundColor: colors.white,
    borderRadius: 8,
    overflow: 'hidden',
    marginBottom: 10,
  },
  headingContainer: {
    backgroundColor: colors.primary,
  },
  headingText: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 10,
    color: colors.white,
  },
  contentContainer: {
    padding: 16,
  },
  formGroup: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  formLabel: {
    width: '30%',
    fontSize: 16,
    fontWeight: 'bold',
  },
  formControl: {
    flex: 1,
  },
});

export default styles;
