import { StyleSheet } from 'react-native';
import colors from '../../constants/colors';

const styles = StyleSheet.create({
  input: {
    paddingHorizontal: 24,
    paddingVertical: 10,
    marginBottom: 16,
    fontSize: 15,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: colors.black,
  },
  label: {
    fontSize: 18,
    color: colors.black,
    marginBottom: 8,
  },
  hasBackground: {
    backgroundColor: colors.gray,
    borderWidth: 0,
  },
});

export default styles;
