import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NavigationContainer } from '@react-navigation/native';
import { store, persistor } from './src/store';
import Screens from './src/screen';
import Loading from './src/components/Loading';
// import { API_URL } from '@env';
import { TailwindProvider } from "tailwindcss-react-native";

const queryClient = new QueryClient();

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <TailwindProvider>
          <NavigationContainer>
            <Screens />
          </NavigationContainer>
          </TailwindProvider>
          <Loading />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}
