import { useState } from 'react';
import { View, Text, TextInput, Pressable } from 'react-native';
import { Controller } from 'react-hook-form';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import colors from '../../constants/colors';
import styles from './styles';

const CustomTextInputPassword = ({
  control,
  name,
  label,
  rules = {},
  ...restProps
}) => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <View style={styles.container}>
          {label && (
            <View style={styles.label}>
              <Text style={styles.labelText}>{label}</Text>
              {rules?.required && <Text style={styles.labelRequired}>*</Text>}
            </View>
          )}

          <View style={styles.inputContainer}>
            <TextInput
              value={value}
              onChangeText={onChange}
              onBlur={onBlur}
              style={[
                styles.input,
                { borderColor: error ? colors.red : colors.black },
              ]}
              {...restProps}
              secureTextEntry={isVisible}
            />

            <Pressable
              style={styles.toggleIcon}
              onPress={() => setIsVisible((prev) => !prev)}
            >
              <MaterialCommunityIcons
                name={isVisible ? 'eye' : 'eye-off'}
                size={24}
                color='black'
              />
            </Pressable>
          </View>

          {error && (
            <Text style={styles.error}>{error.message || 'Error'}</Text>
          )}
        </View>
      )}
    />
  );
};

export default CustomTextInputPassword;
