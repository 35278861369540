import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  formGroupContainer: {
    marginBottom: 16,
  },
});

export default styles;
