import React from "react";
import { Picker } from "@react-native-picker/picker";
import styles from "./styles";
import { View } from "react-native";

const Select = React.forwardRef(
  ({ onChange, value, style = {}, pickerStyle = {}, options = [] }, ref) => {
    return (
      <View style={[styles.container, style]}>
        <Picker
          ref={ref}
          selectedValue={value}
          onValueChange={onChange}
          style={[styles.picker, pickerStyle]}
          itemStyle={styles.pickerItem}
        >
          {options.map((item) => (
            <Picker.Item
              key={item.value}
              label={item.label}
              value={item.value}
            />
          ))}
        </Picker>
      </View>
    );
  }
);

export default Select;
