import React from 'react';
import { TextInput, View, Text } from 'react-native';
import styles from './styles';

const Input = ({ label, ...props }) => {
  return (
    <View>
      {label && <Text style={styles.label}>{label}</Text>}
      <TextInput style={[styles.input]} {...props} />
    </View>
  );
};



export default React.memo(Input);
