import { useQuery } from '@tanstack/react-query';
import axiosClient from './axiosClient';

const productApi = {
  getAll(params) {
    return axiosClient.get('/home/list_attribute', { params });
  },
};

export const useGetProducts = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['products', params],
    queryFn: () => productApi.getAll(params),
    select: (response) => response?.[0]?.[0]?.children?.[0]?.children,
    ...options,
  });
};
