import React from 'react';
import { View, Text, Image, StyleSheet, TouchableOpacity } from 'react-native';
import logoImg from '../../assets/images/logo.png';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import colors from '../../constants/colors';
import { formatCurrency } from '../../utils/formatUtils';

const Order = ({ order, canEdit = false }) => {
  const { ten_kh, phone, order_price } = order;

  return (
    <TouchableOpacity activeOpacity={0.8} style={styles.container}>
      <Image source={logoImg} style={styles.logo} />

      <View style={styles.infoContainer}>
        <Text style={styles.name}>{ten_kh}</Text>
        <Text style={styles.text}>Số điện thoại: {phone}</Text>
        <Text style={styles.text}>Giá trị đơn hàng: {formatCurrency(order_price)}</Text>
      </View>

      {canEdit && (
        <View style={styles.edit}>
          <MaterialCommunityIcons
            name='pencil-outline'
            size={24}
            color='black'
          />
        </View>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 16,
    paddingHorizontal: 8,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 4,
    marginBottom: 18,
  },
  logo: {
    width: 70,
    height: 70,
  },
  infoContainer: {
    paddingHorizontal: 18,
    flex: 1,
  },
  name: {
    fontSize: 14,
    color: colors.black,
    fontWeight: 'bold',
  },
  text: {
    fontSize: 12,
    color: 'rgba(0,0,0,0.4)',
  },
  edit: {
    paddingHorizontal: 4,
    color: colors.primary,
  },
});

export default React.memo(Order);
