import React from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import colors from '../../../../constants/colors';

const ButtonOpenCamera = ({ style, onPress }) => {
  return (
    <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
      <Entypo name='camera' size={40} color='black' />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: colors.black,
    alignItems: 'center',
    justifyContent: 'center',
    // marginLeft: 20,
  },
});

export default React.memo(ButtonOpenCamera);
