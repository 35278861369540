import React from 'react';
import { View, Text } from 'react-native';
import styles from './styles';

const StoreOverview = ({ name, address, planName, store_code, working_time }) => {
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Text style={[styles.text, styles.name]}>{name}</Text>
        <Text style={[styles.text, styles.date]}>{planName}</Text>
        <Text style={styles.text}>{store_code}</Text>
        <Text style={styles.text}>{address}</Text>
        <Text style={styles.text}>Ca {working_time}</Text>
      </View>
    </View>
  );
};

export default React.memo(StoreOverview);
