import { StyleSheet } from 'react-native';
import colors from '../../constants/colors';

const styles = StyleSheet.create({
  label: {
    flexDirection: 'row',
    marginBottom: 8,
  },
  labelText: {
    fontSize: 16,
    color: colors.black,
  },
  labelRequired: {
    color: colors.red,
    marginLeft: 4,
  },
  input: {
    borderWidth: 1,
    borderColor: colors.black,
    borderRadius: 8,
    fontSize: 16,
    color: colors.black,
    paddingVertical: 10,
    paddingHorizontal: 8,
  },
  errorMessage: {
    color: colors.red,
  },
});

export default styles;
