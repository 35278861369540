export const SCREEN_NAME = {
  MAP: 'MAP',
  SIGN_IN: 'SIGN_IN',
  PLAN_LIST: 'PLAN_LIST',
  WORKING: 'WORKING',
  PHOTO_LIST: 'PHOTO_LIST',
  CAMERA: 'CAMERA',
  CONFIRM_INO: 'CONFIRM_INO',
  ADD_ORDER: 'ADD_ORDER',
  SIGN_UP: 'SIGN_UP',
  PROFILE: 'PROFILE',

  ORDERS: 'ORDERS',

  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
};
