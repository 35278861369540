import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';

import authReducer from './slices/authSlice';

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  version: 1,
  whitelist: ['auth'],
  // blacklist: []
};

const rootReducer = combineReducers({
  auth: authReducer,
});

const reducer = (state, action) => {
  // if (action.type === doLogout.type) {
  //   return rootReducer(
  //     { ...state, permission: { ...state.permission, currentRole: null } },
  //     action,
  //   );
  // }

  return rootReducer(state, action);
};

export default persistReducer(persistConfig, reducer);
