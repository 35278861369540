import React from 'react';
import { View, Text } from 'react-native';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './styles';
import Button from '../../../components/Button';
import CustomTextInput from '../../../components/CustomTextInput';
import CustomCheckbox from '../../../components/CustomCheckbox';
import { useRegister } from '../../../api/authApi';
import { SCREEN_NAME } from '../../../constants/screens';
import Layout from '../components/Layout';
import { CustomAlert } from '../../../components/CustomAlert';
import { Link } from '@react-navigation/native';

const schema = yup.object().shape({
  full_name: yup.string().required('Bắt buộc'),
  user: yup.string().required('Bắt buộc'),
  // email: yup.string().email('Email không hợp lệ').required('Bắt buộc'),
  // phone: yup
  //   .string()
  //   .required('Bắt buộc')
  //   .matches(
  //     /^(0|\+84)(\s|\.)?((3[0-9])|(5[0-9])|(7[0-9])|(8[0-9])|(9[0-9]))(\d)(\s|\.)?(\d{3})(\s|\.)?(\d{3})$/,
  //     'Số điện thoại không hợp lệ'
  //   ),
  pass: yup.string().required('Bắt buộc'),
  confirm_pass: yup
    .string()
    .required('Bắt buộc')
    .oneOf([yup.ref('pass'), null], 'Mật khẩu phải trùng khớp'),
});

const SignUp = ({ navigation }) => {
  const { control, handleSubmit, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      accept: false,
    },
  });
  const registerMutation = useRegister();

  const onSubmit = ({ accept, confirm_pass, ...values }) => {
    registerMutation.mutate(values, {
      onSuccess(response) {
        if (response) {
          navigation.reset({
            index: 0,
            routes: [{ name: SCREEN_NAME.SIGN_IN }],
          });
        } else {
          CustomAlert('Tài khoản đã được đăng ký', 'Vui lòng thử lại');
        }
      },
    });
  };

  return (
    <Layout title='Đăng ký'>
      <View>
        <View style={styles.formGroup}>
          <CustomTextInput
            control={control}
            name='full_name'
            label='Họ và tên'
          />
        </View>

        <View style={styles.formGroup}>
          <CustomTextInput
            control={control}
            name='user'
            label='Tên tài khoản'
          />
        </View>

        <View style={styles.formGroup}>
          <CustomTextInput
            control={control}
            name='pass'
            label='Mật khẩu'
            secureTextEntry
          />
        </View>

        <View style={styles.formGroup}>
          <CustomTextInput
            control={control}
            name='confirm_pass'
            label='Nhập lại mật khẩu'
            secureTextEntry
          />
        </View>

        <View style={styles.formGroup}>
          <CustomCheckbox
            control={control}
            name='accept'
            label='Bằng cách tạo tài khoản, tôi đồng ý với chính sách bảo mật và điều khoản sử dụng'
            rules={{ required: 'Required' }}
          />
        </View>

        <View style={styles.formGroup}>
          <Button onPress={handleSubmit(onSubmit)} disabled={!watch('accept')}>
            Đăng ký
          </Button>
        </View>

        <View style={styles.formGroup}>
          <Text style={styles.infoTextContainer}>
            Đã có tài khoản.{' '}
            <Link to={{ screen: SCREEN_NAME.SIGN_IN }}>
              <Text style={styles.infoText}>Đăng nhập ngay</Text>
            </Link>
          </Text>
        </View>
      </View>
    </Layout>
  );
};

export default React.memo(SignUp);
