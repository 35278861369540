import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import Collapsible from 'react-native-collapsible';
import { Entypo } from '@expo/vector-icons';
import colors from '../../../../constants/colors';

const BoxCollapse = ({ title, children, contentStyles = {} }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={() => setIsCollapsed((prev) => !prev)}>
        <View style={styles.headerContainer}>
          <Text style={styles.headerText}>{title}</Text>
          <Entypo
            name={`chevron-${isCollapsed ? 'right' : 'down'}`}
            size={24}
            color={colors.white}
          />
        </View>
      </TouchableOpacity>
      <Collapsible collapsed={isCollapsed}>
        <View style={[styles.content, contentStyles]}>{children}</View>
      </Collapsible>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  headerContainer: {
    backgroundColor: colors.primary,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 18,
  },
  headerText: {
    fontSize: 16,
    color: colors.white,
  },
  content: {
    borderWidth: 1,
    borderColor: colors.primary,
    backgroundColor: colors.white,
    padding: 18,
  },
});

export default React.memo(BoxCollapse);
