import { useMutation } from '@tanstack/react-query';
import axiosClient, {xhrClientPost} from './axiosClient';
      
export const imageApi = {
  create(formData) {

    const config = {
      method: "post",
      url: '/home/upload_images',
      // responseType: "json",
      headers: {
          'Content-Type': 'multipart/form-data',
          // if backend supports u can use gzip request encoding
          // "Content-Encoding": "gzip",
      },
      transformRequest: (data, headers) => {
          // !!! override data to return formData
          // since axios converts that to string
          return formData;
      },
      onUploadProgress: (progressEvent) => {
          // use upload data, since it's an upload progress
          // iOS: {"isTrusted": false, "lengthComputable": true, "loaded": 123, "total": 98902}
      },
      data: formData,
    };

    // send post request and get response
    return axiosClient.request(config);


    // return axiosClient.post('/home/upload_images', data, {
    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //   },
    // });


    // let url = '/home/upload_images';
    // return xhrClientPost(url, data);
  },
  delete(data) {
    return axiosClient.post('/home/delete_store_image', data);
  },
};

export const useCreateImage = () => {
  return useMutation({
    mutationFn: imageApi.create,
    retry: 3,
  });
};

export const useDeleteImage = () => {
  return useMutation(imageApi.delete);
};
