import React from 'react';
import {
  SafeAreaView,
  ScrollView,
  View,
  Text,
  ActivityIndicator,
  Platform
} from 'react-native';
import MapView, { Marker, PROVIDER_GOOGLE } from 'react-native-maps';
import { useForm } from 'react-hook-form';
import styles from './styles';
import CustomSelect from '../../components/CustomSelect';
import { HOURS, MINUTES, BOOTH } from '../../constants/common';
import CustomButton from '../../components/CustomButton';

const MapInfoView = ({ location, isFindingLocation, onPress, isCheckout }) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      start_time: {
        hours: HOURS[0],
        minutes: MINUTES[0],
      },
      end_time: {
        hours: HOURS[0],
        minutes: MINUTES[0],
      },
      booth: BOOTH[0],
    },
  });

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView>
        <View style={styles.mapWrapper}>
          <MapView
            style={styles.map}
            region={location}
            loadingEnabled
            provider={PROVIDER_GOOGLE}
          >
            {(Platform.OS == 'web') ? <MapView.Marker coordinate={location} ></MapView.Marker> : <Marker coordinate={location} /> }
          </MapView>
        </View>
        <View style={styles.coordsContainer}>
          <View style={styles.coordsContent}>
            <View style={styles.coordsLabelContainer}>
              <Text style={styles.coordsLabel}>
                Tọa độ {isCheckout ? 'checkout' : 'check in'}
              </Text>
            </View>
            <Text style={styles.coordsNum}>
              {isFindingLocation ? (
                <ActivityIndicator size='large' color='#000' />
              ) : (
                <>
                  {location.latitude}:{location.longitude}
                </>
              )}
            </Text>
          </View>

          {!isCheckout && (
            <>
              <View style={styles.timeSection}>
                <View style={styles.timeTitleContainer}>
                  <Text style={styles.timeTitleText}>Giờ bắt đầu</Text>
                </View>

                <View style={styles.timeContainer}>
                  <View style={styles.timeSelectContainer}>
                    <CustomSelect
                      control={control}
                      name='start_time.hours'
                      options={HOURS.map((item) => ({
                        value: item,
                        label: item,
                      }))}
                    />
                  </View>

                  <View style={styles.timeSelectContainer}>
                    <CustomSelect
                      control={control}
                      name='start_time.minutes'
                      options={MINUTES.map((item) => ({
                        value: item,
                        label: item,
                      }))}
                    />
                  </View>
                </View>
              </View>

              <View style={styles.timeSection}>
                <View style={styles.timeTitleContainer}>
                  <Text style={styles.timeTitleText}>Giờ kết thúc</Text>
                </View>

                <View style={styles.timeContainer}>
                  <View style={styles.timeSelectContainer}>
                    <CustomSelect
                      control={control}
                      name='end_time.hours'
                      options={HOURS.map((item) => ({
                        value: item,
                        label: item,
                      }))}
                    />
                  </View>

                  <View style={styles.timeSelectContainer}>
                    <CustomSelect
                      control={control}
                      name='end_time.minutes'
                      options={MINUTES.map((item) => ({
                        value: item,
                        label: item,
                      }))}
                    />
                  </View>
                </View>
              </View>

              <View style={styles.timeSection}>
                <View style={styles.timeTitleContainer}>
                  <Text style={styles.timeTitleText}>Booth</Text>
                </View>

                <View style={styles.timeContainer}>
                  <View style={styles.timeSelectContainer}>
                    <CustomSelect
                      control={control}
                      name='booth'
                      options={BOOTH.map((item) => ({
                        value: item,
                        label: item,
                      }))}
                    />
                  </View>
                </View>
              </View>
            </>
          )}

          {!isFindingLocation && (
            <View style={styles.btnContainer}>
              <CustomButton onPress={handleSubmit(onPress)}>
                {isCheckout ? 'Check Out' : 'Check In'}
              </CustomButton>
            </View>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default React.memo(MapInfoView);
