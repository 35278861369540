import { Link } from '@react-navigation/native';
import React from 'react';
import { View, Text, Image } from 'react-native';
import AvatarIcon from '../../assets/images/avatar.svg';
import { SCREEN_NAME } from '../../constants/screens';
import styles from './styles';

const UserOverview = ({ data, progress }) => {
  const dateObj = new Date();
  const month = dateObj.getUTCMonth() + 1; //months from 1-12
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();
  const newDate = day + '/' + month + '/' + year;

  return (
    <View>
      <View style={styles.cardInfo}>
        {data?.user_image ? (
          <Image
            className='w-14  object-cover'
            source={{
              uri: `${data?.user_image}`,
            }}
          />
        ) : (
          <AvatarIcon width={56} />
        )}

        <Link to={{ screen: SCREEN_NAME.PROFILE }}>
          <Text style={styles.username}>{data?.fullname}</Text>
        </Link>
      </View>

      <View>
        <View style={styles.info}>
          <Text style={styles.text}>Công việc trong ngày</Text>
          <Text style={styles.text}>
            {progress?.finishes} / {progress?.all}
          </Text>
        </View>

        <View style={styles.info}>
          <Text style={styles.text}>Thời gian</Text>
          <Text style={styles.text}>{newDate}</Text>
        </View>
      </View>
    </View>
  );
};

export default React.memo(UserOverview);
