import { useQueryClient, useMutation } from '@tanstack/react-query';
import axiosClient from './axiosClient';

const oderApi = {
  upload(data) {
    return axiosClient.post('/home/upload_orders', data);
  },
};

export const uploadOderApi = ({ params } = {}) => {
  const queryClient = useQueryClient();

  return useMutation(oderApi.upload, {
    onSuccess() {
      queryClient.invalidateQueries(['plans', 'detail', params]);
    },
  });
};
