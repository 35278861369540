import { StyleSheet, StatusBar, Platform } from 'react-native';
import colors from '../../../../constants/colors';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0,
  },
  wrapper: {
    padding: 22,
  },
  logo: {
    alignItems: 'flex-end',
  },
  titleContainer: {
    marginVertical: 40,
  },
  titleText: {
    fontSize: 28,
    color: colors.black,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export default styles;
