import axios from 'axios';

const API_URL = "https://cps.webdigital.vn/r8";
// const API_URL = "https://innbi.vn/r8";

const axiosClient = axios.create({
  baseURL: `${API_URL}`,
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

axiosClient.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  return Promise.reject(error);
});

axiosClient.interceptors.response.use(function (response) {
  return response.data;
}, function (error) {
  return Promise.reject(error);
});

export function myLogger() {
  console.log(myNumbers, animals);
}

function xhrPost (url, formData) {

  return new Promise((resolve, reject) => {

    const xhr = new XMLHttpRequest();

    xhr.onload = () => resolve(JSON.parse(xhr.responseText));
    xhr.onerror = () => reject(xhr.statusText);
    xhr.open('post', url, true);
    xhr.send(formData);
  });
}
export async function xhrClientPost (url, formData) {
  url = API_URL + '/home/upload_images';
  console.log('xhrClientPost - url', url);
  let response = await xhrPost(url, formData);

  return response;
}

export default axiosClient;
