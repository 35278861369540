import React from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';
import { usePlan } from '../../api/planApi';
import CustomButton from '../../components/CustomButton';
import Order from '../../components/Order';
import { SCREEN_NAME } from '../../constants/screens';

const Orders = ({ route, navigation }) => {
  const { planId } = route.params;
  const { data } = usePlan({
    params: { plan_id: planId },
  });

  return (
    <View style={styles.container}>
      <View style={styles.btnContainer}>
        <CustomButton
          onPress={() => navigation.navigate(SCREEN_NAME.ADD_ORDER, { planId })}
        >
          Nhập liệu ngay
        </CustomButton>
      </View>

      {data?.order?.length > 0 && (
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.listContainer}
        >
          {data?.order?.map((order) => (
            <Order key={order.order_id} order={order} />
          ))}
        </ScrollView>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 24,
  },
  listContainer: {
    paddingHorizontal: 24,
  },
  btnContainer: {
    marginBottom: 16,
    paddingHorizontal: 24,
  },
});

export default React.memo(Orders);
