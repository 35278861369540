import _ from 'lodash';

export const checkIsImagesOk = (imageTypes = [], images = []) => {
  const groupByImages = _.groupBy(images, 'shelves_id');

  return _.every(
    imageTypes,
    (item) => groupByImages[item.id]?.length >= item.min
  );
};
