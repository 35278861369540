import { useQuery } from '@tanstack/react-query';
import axiosClient from './axiosClient';

const planApi = {
  getAll(data) {
    return axiosClient.post('/home/list_plan', data);
  },
  getById(id) {
    return axiosClient.post('/home/plan_detail', id);
  },
};

export const usePlans = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['plans', 'list', params],
    queryFn: () => planApi.getAll(params),
    ...options,
  });
};

export const usePlan = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['plans', 'detail', params],
    queryFn: () => planApi.getById(params),
    ...options,
  });
};
