import { StyleSheet } from 'react-native';
import colors from '../../constants/colors';

const styles = StyleSheet.create({
  cardInfo: {
    alignItems: 'center',
    marginBottom: 12,
  },
  username: {
    fontSize: 28,
    fontWeight: 'bold',
    color: colors.black,
    marginTop: 18,
  },
  info: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 8,
  },
  text: {
    fontSize: 18,
    color: colors.black,
  },
});

export default styles;
