import { useMutation } from '@tanstack/react-query';
import axiosClient from './axiosClient';

export const authApi = {
  login(data) {
    return axiosClient.post('/home/login', data);
  },
  checkAppVersion(data) {
    return axiosClient.post('/home/version', data);
  },
  register(data) {
    return axiosClient.post('/home/register', data);
  },
  deleteUser(data) {
    return axiosClient.post('/home/delete_user', data);
  },
  changePassword(data) {
    return axiosClient.post('/home/change_pass', data);
  },
};

export const useCheckAppVersion = () => {
  return useMutation(authApi.checkAppVersion);
};

export const useRegister = () => {
  return useMutation(authApi.register);
};

export const useDeleteUser = () => {
  return useMutation(authApi.deleteUser);
};

export const useChangePassword = () => {
  return useMutation(authApi.changePassword);
};
