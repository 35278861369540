import { View, Text, TextInput, StyleSheet } from 'react-native';
import { Controller } from 'react-hook-form';
import colors from '../../constants/colors';
import styles from './styles';

const CustomTextInput = ({
  control,
  name,
  label,
  rules = {},
  ...restProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <>
          <View style={styles.container}>
            {label && (
              <View style={styles.label}>
                <Text style={styles.labelText}>{label}</Text>
                {rules?.required && <Text style={styles.labelRequired}>*</Text>}
              </View>
            )}

            <TextInput
              value={value}
              onChangeText={onChange}
              onBlur={onBlur}
              style={[
                styles.input,
                { borderColor: error ? colors.red : colors.black },
              ]}
              {...restProps}
            />
          </View>

          {error && (
            <Text style={styles.error}>{error.message || 'Error'}</Text>
          )}
        </>
      )}
    />
  );
};

export default CustomTextInput;
