import React from 'react';
import { View, Text } from 'react-native';
import { Controller } from 'react-hook-form';
import styles from './styles';
import Select from '../../../../components/Select';
import { TextInputCustom } from '../../../../components/TextInput';
import BoxCollapse from '../../../camera/components/BoxCollapse';
import _ from 'lodash';

const ProductItem = ({
  control,
  index,
  options = [],
  onChangePrice = () => {},
}) => {
  return (
    <View style={styles.container}>
      <BoxCollapse
        title={`Sản phẩm ${index + 1}`}
        contentStyles={{ padding: 0, borderColor: 'transparent' }}
      >
        <View style={styles.contentContainer}>
          <View style={styles.formGroup}>
            <Text style={styles.formLabel}>Tên</Text>

            <View style={styles.formControl}>
              <Controller
                name={`order_sanpham.${index}.id`}
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      style={{ borderRadius: 0, width: '100%' }}
                      options={options}
                      onChange={(value, idx) => {
                        onChangePrice(
                          `order_sanpham.${index}.price`,
                          options[idx]?.price ?? 0
                        );
                        field.onChange(value);
                      }}
                    />
                  );
                }}
              />
            </View>
          </View>

          <View style={styles.formGroup}>
            <Text style={styles.formLabel}>Giá</Text>

            <View style={styles.formControl}>
              <Controller
                name={`order_sanpham.${index}.price`}
                control={control}
                render={({ field }) => {
                  return (
                    <TextInputCustom
                      {...field}
                      inputStyles={{
                        borderRadius: 0,
                      }}
                      keyboardType='numeric'
                      editable={false}
                      selectTextOnFocus={false}
                    />
                  );
                }}
              />
            </View>
          </View>

          <View style={styles.formGroup}>
            <Text style={styles.formLabel}>Số lượng</Text>

            <View style={styles.formControl}>
              <Controller
                name={`order_sanpham.${index}.value`}
                control={control}
                render={({ field }) => {
                  return (
                    <TextInputCustom
                      {...field}
                      inputStyles={{
                        borderRadius: 0,
                      }}
                      keyboardType='numeric'
                    />
                  );
                }}
              />
            </View>
          </View>
        </View>
      </BoxCollapse>
    </View>
  );
};

export default React.memo(ProductItem);
