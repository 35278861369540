import Checkbox from 'expo-checkbox';
import { View, Text, StyleSheet } from 'react-native';
import { Controller } from 'react-hook-form';
import colors from '../../constants/colors';

const CustomCheckbox = ({ control, name, rules = {}, label }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <>
          <View style={styles.container}>
            <Checkbox
              value={value}
              color={value ? colors.primary : undefined}
              onValueChange={onChange}
              style={styles.checkbox}
            />
            {label && <Text style={styles.label}>{label}</Text>}
          </View>

          {error && (
            <Text style={styles.error}>{error.message || 'Error'}</Text>
          )}
        </>
      )}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontSize: 16,
    color: colors.black,
    marginLeft: 8,
  },
  checkbox: {},
  error: {
    color: colors.red,
  },
});

export default CustomCheckbox;
