import { StyleSheet } from 'react-native';
import colors from '../../constants/colors';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 35,
    paddingHorizontal: 16,
    backgroundColor: colors.white,
    borderRadius: 18,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 4,
    marginBottom: 28,
  },
  content: {
    flex: 1,
    paddingLeft: 18,
  },
  name: {
    marginTop: 0,
    fontWeight: 'bold',
    color: colors.primary,
  },
  date: {
    fontWeight: 'bold',
    color: colors.red,
  },
  text: {
    fontSize: 16,
    color: colors.black,
    marginTop: 8,
  },
});

export default styles;
