import React from 'react';
import { SafeAreaView, ScrollView, View } from 'react-native';
import WorkingItem from '../../components/Working/WorkingItem';
import styles from './styles';
import CheckInIcon from '../../assets/images/checkin.svg';
import CameraIcon from '../../assets/images/camera.svg';
import ReportIcon from '../../assets/images/report.svg';
import CheckoutICon from '../../assets/images/checkout.svg';
import StoreOverview from '../../components/StoreOverview';
import { SCREEN_NAME } from '../../constants/screens';
import { usePlan } from '../../api/planApi';
import { useGetCameraList } from '../../api/cameraApi';
import _ from 'lodash';
import { checkIsImagesOk } from './utils';

const WorkingView = ({ navigation, route }) => {
  const { planId } = route.params;
  const { data, isLoading, isError } = usePlan({
    params: { plan_id: planId },
  });

  const { data: cameraList } = useGetCameraList({
    options: {
      enabled: !!data?.plan_id,
      select(response) {
        return response?.camera ?? [];
      },
    },
  });

  if (isLoading) {
    return null;
  }

  if (isError || !data?.plan_id) {
    return null; // Show error
  }

  const isCheckedIn = !!data?.kinhdo_checkin && !!data?.vido_checkin;
  const isImagesOk = checkIsImagesOk(cameraList, data?.store_image);

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.container}>
        <StoreOverview
          name={data.store_name}
          address={data.address_full}
          planName={data.plan_name}
          store_code={data.store_code}
          working_time={data.working_time}
        />

        <View style={styles.workingContainer}>
          <WorkingItem
            icon={CheckInIcon}
            name='Check-in'
            onPress={() =>
              navigation.navigate(SCREEN_NAME.MAP, {
                planId,
              })
            }
            disabled={isCheckedIn}
            isOk={isCheckedIn}
          />
          <WorkingItem
            icon={CameraIcon}
            name='Chụp hình'
            onPress={() =>
              navigation.navigate(SCREEN_NAME.PHOTO_LIST, {
                planId,
              })
            }
            disabled={!isCheckedIn}
            isOk={isImagesOk}
          />
          <WorkingItem
            icon={ReportIcon}
            name='Nhập báo cáo'
            onPress={() =>
              navigation.navigate(SCREEN_NAME.ORDERS, {
                planId,
              })
            }
            disabled={!isCheckedIn}
          />
          <WorkingItem
            icon={CheckoutICon}
            name='Kết quả ngày (ca)'
            onPress={() =>
              navigation.navigate(SCREEN_NAME.CONFIRM_INO, {
                planId,
              })
            }
            disabled={!isCheckedIn}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default React.memo(WorkingView);
