import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Location from 'expo-location';
import * as Device from 'expo-device';
import MapInfoView from './MapInfoView';
import { useCheckIn, useCheckOut } from '../../api/locationApi';
import { SCREEN_NAME } from '../../constants/screens';
import { getCurrentUser } from '../../store/slices/authSlice';
import { APP_VERSION } from '../../config/app';
import { usePlans } from '../../api/planApi';

const MapContainer = ({ navigation, route }) => {
  const { planId, isCheckout = false } = route.params;
  const currentUser = useSelector(getCurrentUser);
  const [location, setLocation] = useState({
    currentLocation: {
      latitude: 10.803938247273493,
      longitude: 106.71503724540277,
      latitudeDelta: 0.015,
      longitudeDelta: 0.0121,
    },
    isFindingLocation: true,
  });
  const checkInMutation = useCheckIn({ params: { plan_id: planId } });
  const checkOutMutation = useCheckOut({
    params: { user_id: currentUser.user_id },
  });

  const { refetch } = usePlans({
    params: { user_id: currentUser?.user_id },
    options: {
      enabled: false,
    },
  });

  const mutation = useMemo(
    () => (isCheckout ? checkOutMutation : checkInMutation),
    [isCheckout]
  );

  useEffect(() => {
    (async () => {
      // const { status } = await Location.requestForegroundPermissionsAsync();
      // console.log({ status });
      // if (status !== 'granted') {
      //   // Show popup and click go back
      //   setErrorMsg('Không có quyền truy cập. Vui lòng thử lại');
      //   return;
      // }

      const location = await Location.getCurrentPositionAsync({});
      setLocation((prev) => ({
        ...prev,
        currentLocation: {
          ...prev.currentLocation,
          latitude: location.coords.latitude,
          longitude: location.coords.longitude,
        },
        isFindingLocation: false,
      }));
    })();
  }, []);

  const handleCheck = (values) => {
    const data = {
      plan_id: planId,
      vido: location.currentLocation.latitude,
      kinhdo: location.currentLocation.longitude,
    };

    if (!isCheckout) {
      data.model = `${Device.deviceName}, ${Device.modelName}, ${Device.osName}, ${Device.osVersion}`;
      data.version = APP_VERSION;
      data.start_time = `${values.start_time.hours}:${values.start_time.minutes}`;
      data.end_time = `${values.end_time.hours}:${values.end_time.minutes}`;
      data.booth = values.booth;
    }

    mutation.mutate(data, {
      onSuccess(response) {
        if (!!response) {
          if (isCheckout) {
            refetch();
            navigation.navigate(SCREEN_NAME.PLAN_LIST);
          } else {
            navigation.goBack();
          }
        }
      },
    });
  };

  return (
    <>
      <MapInfoView
        location={location.currentLocation}
        isFindingLocation={location.isFindingLocation}
        onPress={handleCheck}
        isCheckout={isCheckout}
        onGoBack={navigation.goBack}
      />
    </>
  );
};

export default React.memo(MapContainer);
