import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { authApi } from '../../api/authApi';
import { formatTime } from '../../utils/timeUtils';

export const doLogin = createAsyncThunk('auth/login', async (data) => {
  const result = await authApi.login(data);

  return result;
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    isLoggedIn: false,
    loginDate: null,
  },
  reducers: {
    doLogout: (state) => {
      state.user = null;
      state.isLoggedIn = false;
      state.loginDate = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
      state.loginDate = formatTime(new Date());
    });
  },
});

export const getCurrentUser = (state) => state.auth.user;
export const getIsLoggedIn = (state) => state.auth.isLoggedIn;
export const getLoginDate = (state) => state.auth.loginDate;

const { actions, reducer } = authSlice;
export const { doLogout } = actions;
export default reducer;
