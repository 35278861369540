import { StyleSheet } from 'react-native';
import colors from '../../constants/colors';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  mapWrapper: {
    height: 300,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  map: {
    ...StyleSheet.absoluteFillObject,
  },
  coordsContainer: {
    paddingHorizontal: 24,
    paddingTop: 8,
    paddingBottom: 32,
  },
  coordsContent: {
    alignItems: 'center',
    marginBottom: 8,
  },
  coordsLabelContainer: {
    borderBottomWidth: 1,
    borderBottomColor: colors.black,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 6,
    marginTop: 16,
    marginBottom: 6,
  },
  coordsLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.black,
  },
  coordsNum: {
    fontSize: 16,
    color: colors.black,
  },
  closeIcon: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  timeSection: {
    marginVertical: 8,
  },
  timeTitleContainer: {
    marginBottom: 8,
  },
  timeTitleText: {
    color: colors.black,
    fontWeight: 'bold',
  },
  timeContainer: {
    flexDirection: 'row',
  },
  timeSelectContainer: {
    flex: 1,
  },
  btnContainer: {
    marginTop: 24,
  },
});

export default styles;
